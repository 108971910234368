import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";

//@ts-ignore
import image from "../../images/GreatPlaceToWork.png";

const GreatPlaceToWork = () => {
  const theme = useTheme();
  const isOverSm = useMediaQuery(theme.breakpoints.up("sm"));

  const subtitle = (
    <LifeatmTypography
      variant="body1"
      sx={{
        margin: { xs: "auto", sm: "unset" },
        marginTop: { xs: "16px", md: "24px" },
        width: { xs: "90%", md: "100%" },
      }}
    >
      {l_lifeatm("greatPlaceToWork.description")}
    </LifeatmTypography>
  );

  return (
    <Box
      sx={{
        marginBottom: { md: "360px", xs: "88px" },
        backgroundColor: "white",
        position: "relative",
        paddingY: { md: "32px", xs: "24px" },
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: "1800px",
          margin: "auto",
          display: "flex",
          position: "relative",
          flexDirection: "row",
        }}
      >
        <LifeatmTypography
          variant="title1"
          sx={{
            width: "60%",
            maxWidth: "1000px",
            minWidth: "270px",
          }}
        >
          {l_lifeatm("greatPlaceToWork.title1")}
          <br />
          {l_lifeatm("greatPlaceToWork.title2")}
          <LifeatmTypography
            variant="span"
            sx={{
              fontSize: { xl: "46px", lg: "30px", md: "28px", xs: "20px" },
              verticalAlign: "text-top",
              marginLeft: { md: "8px", xs: "4px" },
            }}
          >
            {l_lifeatm("greatPlaceToWork.R")}
          </LifeatmTypography>
          {isOverSm && subtitle}
        </LifeatmTypography>
        <Box sx={{ flex: 1, position: "relative" }}>
          <Box
            sx={{
              height: { lg: "400px", md: "302px", xs: "100%" },
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "contain",
              backgroundImage: `url(${image})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",

              // top: 0,
              left: 0,
              right: 0,
              bottom: { xs: 0, lg: "20%" },
            }}
          />
        </Box>
      </Box>
      {!isOverSm && subtitle}
    </Box>
  );
};

export default GreatPlaceToWork;
