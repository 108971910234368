import { Box, Stack } from "@mui/material";
import Description from "./Description";
import Title from "./Title";

const BePartOfOurTeam = () => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff", position: "relative" }}>
      <Stack
        sx={{
          maxWidth: "1700px",
          width: "90%",
          margin: "auto",
          flexDirection: { md: "row", xs: "column" },
          paddingTop: { md: "95px", xs: "64px" },
          paddingBottom: { md: "95px", xs: "64px" },
          gap: { md: "32px", xs: "0px" },
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Title />
        <Description />
      </Stack>
    </Box>
  );
};
export default BePartOfOurTeam;
