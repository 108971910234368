/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { ReactNode } from "react";
import ButtonWithUnderline from "../../../../components/shared/ButtonWithUnderline";
import { l_lifeatm } from "../../locales";

const EventPoint = ({
  destination,
  image,
  link,
}: {
  destination: string;
  image: ReactNode;
  link: string | undefined;
}) => {
  return (
    <Box>
      <Typography
        component="h6"
        sx={{
          fontSize: { lg: "24px", xs: "20px" },
          lineHeight: { lg: "32px", xs: "24px" },
          fontWeight: 700,
          marginBottom: { md: "24px", xs: "16px" },
        }}
      >
        {destination}
      </Typography>
      <Box sx={{ marginBottom: { md: "32px", xs: "24px" } }}>{image}</Box>
      {link ? (
        <ButtonWithUnderline
          textStyle={{
            fontSize: { lg: "24px", xs: "20px" },
            linHeight: { lg: "32px", xs: "24px" },
          }}
          defaultUnderlined
          link={link}
        >
          {l_lifeatm("neverAlone.eventLink")}
        </ButtonWithUnderline>
      ) : (
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "20px" },
            linHeight: { lg: "32px", xs: "24px" },
          }}
        >
          {l_lifeatm("neverAlone.eventLinkAbsent")}
        </Typography>
      )}
    </Box>
  );
};

const EventsGrid = () => {
  const events = [
    {
      destination: l_lifeatm("neverAlone.eventDest1"),
      image: (
        <StaticImage
          alt="Javaspritz logo"
          src="../../images/JavaspritzLogo.png"
          css={{ maxWidth: "340px", width: "auto" }}
        />
      ),
      link: "https://javaspritz.dev/",
    },
    {
      destination: l_lifeatm("neverAlone.eventDest2"),
      image: (
        <StaticImage
          alt="UX tea logo"
          src="../../images/UXteaLogo.png"
          css={{ maxWidth: "220px", width: "auto" }}
        />
      ),
      link: "https://uxtea.it/",
    },
    {
      destination: l_lifeatm("neverAlone.eventDest3"),
      image: (
        <StaticImage
          alt="Gingerbit logo"
          src="../../images/GingerbitLogo.png"
          css={{ maxWidth: "400px", width: "auto" }}
        />
      ),
      link: undefined,
    },
  ];
  return (
    <Box
      sx={{
        paddingTop: { md: "320px", xs: "72px" },
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        width: "90%",
        maxWidth: "1700px",
        margin: "auto",
        justifyContent: "space-between",
        gap: "64px",
      }}
    >
      {events.map((event) => (
        <EventPoint
          destination={event.destination}
          link={event.link}
          image={event.image}
        />
      ))}
    </Box>
  );
};

export default EventsGrid;
