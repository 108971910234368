import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";
import ScrollingPhotos from "./ScrollingPhotos";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const APlaceToBelong = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to(".aPlaceToBelongSmBluePill", {
      scrollTrigger: {
        trigger: ".aPlaceToBelongSmBluePill",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: -50,
    });
  }, [isOverMd]);

  return (
    <Box
      sx={{
        paddingTop: { md: "320px", xs: "88px" },
        width: "90%",
        maxWidth: "1700px",
        margin: "auto",
        position: "relative",
      }}
    >
      <Box sx={{ maxWidth: "1250px" }}>
        <LifeatmTypography
          variant="title1"
          sx={{ marginBottom: { md: "56px", xs: "24px" } }}
        >
          {l_lifeatm("aPlaceToBelong.title")}
        </LifeatmTypography>
        <LifeatmTypography variant="body1" sx={{ marginBottom: "0px" }}>
          {l_lifeatm("aPlaceToBelong.description1")}{" "}
          {l_lifeatm("aPlaceToBelong.description2")}
        </LifeatmTypography>
      </Box>
      {!isOverMd && (
        <Box
          sx={{
            position: "absolute",
            width: "32px",
            right: "-2%",
            top: "130px",
          }}
          className="aPlaceToBelongSmBluePill"
        >
          <StaticImage alt="" src="../../images/BluePill.svg" />
        </Box>
      )}

      <ScrollingPhotos />
    </Box>
  );
};

export default APlaceToBelong;
