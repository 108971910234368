import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap, { Linear } from "gsap";
import { useEffect } from "react";

const PhotosSection = ({ className }: { className: string }) => {
  const commonBoxStyle = {
    borderRadius: "24px",
    overflow: "hidden",
    height: "fit-content",
    zIndex: 1,
    position: "relative",
    flexShrink: 0,
  };
  const commonAbsoluteStyle = {
    position: "absolute",
    zIndex: 2,
    borderRadius: "24px",
    overflow: "hidden",
  };
  const commonContainerStyle = {
    // backgroundColor: "red",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    position: "relative",
  };
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "35px",
      }}
      className={className}
    >
      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "460px", xs: "230px" },
          alignSelf: "flex-start",
          marginLeft: { md: "20px", xs: "10px" },
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-1.jpeg" alt="" />
      </Box>

      <Box sx={{ ...commonContainerStyle, left: { md: "12px", xs: "6px" } }}>
        <Box
          sx={{
            ...commonBoxStyle,
            width: { md: "660px", xs: "330px" },
            alignSelf: "flex-end",
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-2.jpeg" alt="" />
        </Box>
        <Box
          sx={{
            ...commonAbsoluteStyle,
            top: 0,
            left: "30%",
            width: { md: "348px", xs: "174px" },
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-3.jpeg" alt="" />
        </Box>
      </Box>

      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "580px", xs: "280px" },
          alignSelf: "flex-start",
          top: { md: "10px", xs: "5px" },
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-4.jpeg" alt="" />
      </Box>

      <Box sx={{ ...commonContainerStyle }}>
        <Box
          sx={{
            ...commonBoxStyle,
            width: { md: "580px", xs: "280px" },
            alignSelf: "flex-start",
            top: { md: "20px", xs: "10px" },
            marginLeft: { md: "250px", xs: "125px" },
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-6.jpeg" alt="" />
        </Box>
        <Box
          sx={{
            ...commonAbsoluteStyle,
            bottom: 0,
            left: "-10%",
            width: { md: "460px", xs: "230px" },
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-5.jpeg" alt="" />
        </Box>
      </Box>
      <Box sx={{ ...commonContainerStyle }}>
        <Box
          sx={{
            ...commonBoxStyle,
            width: { md: "720px", xs: "360px" },
            alignSelf: "flex-start",
            top: "0px",
            marginLeft: { md: "500px", xs: "250px" },
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-8.jpeg" alt="" />
        </Box>
        <Box
          sx={{
            ...commonAbsoluteStyle,
            bottom: 0,
            left: "-15%",
            width: { md: "650px", xs: "325px" },
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-7.jpeg" alt="" />
        </Box>
        <Box
          sx={{
            ...commonAbsoluteStyle,
            bottom: 0,
            right: { md: "60px", xs: "30px" },
            width: { md: "302px", xs: "151px" },
          }}
        >
          <StaticImage src="../../images/AutoCarousel/photo-9.jpeg" alt="" />
        </Box>
      </Box>

      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "460px", xs: "230px" },
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-10.jpeg" alt="" />
      </Box>

      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "348px", xs: "174px" },
          marginLeft: { md: "20px", xs: "10px" },
          alignSelf: "flex-start",
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-11.jpeg" alt="" />
      </Box>

      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "710px", xs: "355px" },
          marginLeft: { md: "-100px", xs: "-50px" },
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-12.jpeg" alt="" />
      </Box>

      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "688px", xs: "344px" },
          alignSelf: "flex-start",
          marginTop: { md: "20px", xs: "10px" },
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-13.jpeg" alt="" />
      </Box>

      <Box
        sx={{
          ...commonBoxStyle,
          width: { md: "420px", xs: "210px" },
          marginLeft: { md: "-100px", xs: "-50px" },
          alignSelf: "flex-end",
          marginBottom: { md: "30px", xs: "15px" },
          marginRight: { md: "10px", xs: "5px" },
        }}
      >
        <StaticImage src="../../images/AutoCarousel/photo-14.jpeg" alt="" />
      </Box>
    </Stack>
  );
};

const PhotosCarousel = () => {
  useEffect(() => {
    gsap.fromTo(
      ".photo-carousel",
      { xPercent: 0 },
      {
        xPercent: -100,
        duration: 50,
        ease: Linear.easeNone,
        repeat: -1,
      }
    );
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: { md: "780px", xs: "390px" },
        overflowX: "hidden",
        marginBottom: { md: "164px", xs: "72px" },
        position: "relative",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          position: "absolute",
        }}
      >
        <PhotosSection className="photo-carousel" />
        <PhotosSection className="photo-carousel" />
      </Stack>
    </Box>
  );
};

export default PhotosCarousel;
