import { Box, Stack } from "@mui/material";
import PhotosMosaic from "./PhotosMosaic";
import Title from "./Title";

const MakeOurEveryProject = () => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "1700px",
        margin: "auto",
        paddingTop: { md: "370px", xs: "88px" },
        paddingBottom: { md: "450px", xs: "250px" },
      }}
    >
      <Stack
        sx={{
          flexDirection: {
            lg: "row",
            xs: "column",
          },
          gap: { xs: "48px", md: "56px", lg: "32px" },
          justifyContent: "space-between",
        }}
      >
        <Title />
        <PhotosMosaic />
      </Stack>
    </Box>
  );
};

export default MakeOurEveryProject;
