import { Box } from "@mui/material";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";
import EventsGrid from "./EventsGrid";
import PhotosCarousel from "./PhotosCarousel";

const NeverAlone = () => {
  return (
    <Box>
      <Box
        sx={{
          maxWidth: "1700px",
          width: "90%",
          margin: "auto",
          marginBottom: { md: "320px", xs: "72px" },
        }}
      >
        <Box sx={{ maxWidth: "1250px" }}>
          <LifeatmTypography
            variant="title1"
            sx={{ marginBottom: { md: "56px", xs: "16px" } }}
          >
            {l_lifeatm("neverAlone.title")}
          </LifeatmTypography>
          <LifeatmTypography
            variant="body1"
            sx={{ marginBottom: { md: "32px", xs: "20px" } }}
          >
            {l_lifeatm("neverAlone.description1")}
          </LifeatmTypography>
          <LifeatmTypography variant="body1" sx={{}}>
            {l_lifeatm("neverAlone.description2")}
          </LifeatmTypography>
        </Box>
      </Box>
      <PhotosCarousel />
      <EventsGrid />
    </Box>
  );
};

export default NeverAlone;
