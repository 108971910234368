import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../components/old/seo_head";
import LifeAtMabiloft from "../features/life_at_mabiloft";
import { l_lifeatm } from "../features/life_at_mabiloft/locales";

export default LifeAtMabiloft;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title="Life at Mabiloft"
      description={l_lifeatm("seo.description")}
      headProps={headProps}
    />
  );
};
