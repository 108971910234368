import { Box, Typography } from "@mui/material";
import { l_lifeatm } from "../../locales";

const Title = () => {
  return (
    <Box
      sx={{
        width: { lg: "55%", md: "40%", xs: "100%" },
        maxWidth: { md: "600px", xs: "unset" },
        boxSizing: "border-box",
        marginBottom: { md: 0, xs: "16px" },
      }}
    >
      <Typography
        component="h4"
        sx={{
          fontSize: { lg: "48px", md: "36px", xs: "24px" },
          lineHeight: { lg: "52px", md: "40px", xs: "32px" },
          fontWeight: 700,
        }}
      >
        {l_lifeatm("bePartOfOurTeam.title")}
      </Typography>
    </Box>
  );
};
export default Title;
