import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";
import Decorations from "./Decorations";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box>
      <Box
        sx={{
          width: "90%",
          margin: "auto",
          // border: "1px solid red",
          paddingTop: { md: "127px", xs: "20px" },
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            boxSizing: "border-box",
            maxWidth: "1700px",
            margin: "auto",
            marginBottom: { md: "225px", xs: "80px" },
          }}
        >
          <Box
            sx={{
              width: { md: "60%", xs: "100%" },
              // border: "1px solid green"
            }}
          >
            <LifeatmTypography
              variant="overtitle"
              sx={{ marginBottom: { md: "56px", xs: "16px" } }}
            >
              life at mabiloft
            </LifeatmTypography>
            <LifeatmTypography
              variant="title1"
              sx={{
                marginBottom: { md: "56px", xs: "16px" },
                maxWidth: "900px",
              }}
            >
              {l_lifeatm("title.title")}
            </LifeatmTypography>
            <LifeatmTypography variant="body1">
              {l_lifeatm("title.description")}
            </LifeatmTypography>
          </Box>

          <Box
            sx={{
              width: "40%",
              maxWidth: "450px",
              marginLeft: "auto",
              // border: "1px solid lime"
              display: { md: "block", xs: "none" },
            }}
          >
            <Decorations />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "auto", xl: "1100px !important" },
          overflow: "hidden",
          // maxWidth: "100%",
          margin: "auto",

          "& img": {
            objectPosition: "center 40%",
            objectFit: "cover",
            height: {
              xs: "auto",
              xl: "1100px !important",
            },
          },
        }}
      >
        <StaticImage
          src="../../images/BigPhoto.jpeg"
          alt="Group photo of the team"
          layout="fullWidth"
        />
      </Box>
    </Box>
  );
};

export default Title;
