import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

const PhotosFirstLine = ({ className }: { className: string }) => {
  const commonBoxProps = {
    borderRadius: "180px",
    overflow: "hidden",
    flexShrink: 0,
    height: "100%",
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        height: { md: "360px", xs: "180px" },
        width: "fit-content",
        position: "relative",
      }}
      className={className}
    >
      <Box sx={{ ...commonBoxProps, width: { md: "360px", xs: "180px" } }}>
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-1.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>

      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "670px", xs: "335px" },
        }}
      >
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-2.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>

      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "360px", xs: "180px" },
          backgroundColor: "rgb(87, 214, 249)",
        }}
      />

      <Box sx={{ ...commonBoxProps, width: { md: "500px", xs: "250px" } }}>
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-3.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>

      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "500px", xs: "250px" },
          backgroundColor: "rgb(255, 163, 0)",
        }}
      />

      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "360px", xs: "180px" },
          backgroundColor: "rgb(87, 214, 249)",
        }}
      />

      <Box sx={{ ...commonBoxProps, width: { md: "500px", xs: "250px" } }}>
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-4.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>
    </Stack>
  );
};

const PhotosSecondLine = ({ className }: { className: string }) => {
  const commonBoxProps = {
    borderRadius: "180px",
    overflow: "hidden",
    flexShrink: 0,
    height: "100%",
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        height: { md: "360px", xs: "180px" },
        width: "fit-content",
        position: "relative",
      }}
      className={className}
    >
      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "500px", xs: "250px" },
          backgroundColor: "rgb(255, 163, 0)",
        }}
      />

      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "670px", xs: "335px" },
        }}
      >
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-5.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>

      <Box sx={{ ...commonBoxProps, width: { md: "500px", xs: "250px" } }}>
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-6.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>

      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "360px", xs: "180px" },
          backgroundColor: "rgb(87, 214, 249)",
        }}
      />

      <Box sx={{ ...commonBoxProps, width: { md: "500px", xs: "250px" } }}>
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-7.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>

      <Box sx={{ ...commonBoxProps, width: { md: "500px", xs: "250px" } }}>
        <StaticImage
          alt=""
          src="../../images/ScrollCarousel/photo-8.jpeg"
          imgStyle={{ borderRadius: "180px" }}
        />
      </Box>
      <Box
        sx={{
          ...commonBoxProps,
          width: { md: "360px", xs: "180px" },
          backgroundColor: "rgb(87, 214, 249)",
        }}
      />
    </Stack>
  );
};

gsap.registerPlugin(ScrollTrigger);

const PhotosCarousel = () => {
  useEffect(() => {
    gsap.fromTo(
      ".firstLineCarousel",
      {
        xPercent: 0,
      },
      {
        scrollTrigger: {
          trigger: ".firstLineCarousel",
          // markers: true,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },

        xPercent: -70,
      }
    );
    gsap.fromTo(
      ".secondLineCarousel",
      { xPercent: -30 },
      {
        scrollTrigger: {
          trigger: ".secondLineCarousel",
          // markers: true,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
        xPercent: 0,
      }
    );
  }, []);

  return (
    <Box sx={{}}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <PhotosFirstLine className={"firstLineCarousel"} />
        <PhotosFirstLine className={"firstLineCarousel"} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <PhotosSecondLine className={"secondLineCarousel"} />
        <PhotosSecondLine className={"secondLineCarousel"} />
      </Box>
    </Box>
  );
};

export default PhotosCarousel;
