/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Decorations = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to(".headerBlueLemon2", {
      scrollTrigger: {
        trigger: ".headerDecorationsContainer",
        scrub: 2,
        start: "top 20%",
        end: "bottom 20%",
      },
      y: 90,
      rotate: 70,
    });
    gsap.to(".headerBlueLemon1", {
      scrollTrigger: {
        trigger: ".headerDecorationsContainer",
        scrub: 2,
        start: "top 20%",
        end: "bottom 20%",
      },
      y: 90,
      x: 10,
      rotate: -50,
    });
    gsap.to(".headerOrangeCircle", {
      scrollTrigger: {
        trigger: ".headerDecorationsContainer",
        scrub: 2,
        start: "top center",
        end: "bottom 20%",
      },
      y: 90,
      x: -50,
    });
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        // backgroundColor: "greenyellow",
      }}
      className="headerDecorationsContainer"
    >
      <Box
        sx={{
          width: { lg: "210px", md: "180px" },
          // backgroundColor: "pink",
          transform: "translateX(-10%) rotate(-130deg)",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        className="headerBlueLemon1"
      >
        <StaticImage src={"../../images/BlueLemon.svg"} alt={""} />
      </Box>

      <Box
        sx={{
          width: { lg: "140px", md: "120px" },
          // backgroundColor: "violet",
          position: "absolute",
          // transformOrigin: "bottom right",
          bottom: 0,
          left: "20%",
          transform: "rotate(133deg)",
        }}
        className="headerBlueLemon2"
      >
        <StaticImage src={"../../images/BlueLemon.svg"} alt={""} width={140} />
      </Box>

      <Box
        sx={{
          width: { lg: "140px", md: "110px" },
          // backgroundColor: "peachpuff",
          position: "absolute",
          top: "30%",
          right: "0%",
        }}
        className="headerOrangeCircle"
      >
        <StaticImage src={"../../images/OrangeCircle.svg"} alt={""} />
      </Box>
    </Box>
  );
};

export default Decorations;
