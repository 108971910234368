import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
// @ts-ignore
import ScrollingPhoto3 from "../../images/ScrollingPhoto3.png";
// @ts-ignore
import ScrollingPhoto4 from "../../images/ScrollingPhoto4.png";

const ScrollingPhotos = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      ".aPlaceToBelongScrollingImage1",
      {
        backgroundPosition: "60% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".aPlaceToBelongScrollingImagesContainer",
          scrub: 1,
          start: "top center",
          end: "bottom bottom",
        },
        backgroundPosition: "60% 100%",
      }
    );
    gsap.fromTo(
      ".aPlaceToBelongScrollingImage2",
      {
        backgroundPosition: "50% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".aPlaceToBelongScrollingImagesContainer",
          scrub: 1,
          start: "top center",
          end: "bottom bottom",
        },
        backgroundPosition: "50% 70%",
      }
    );

    gsap.to(".aPlaceToBelongOrangeLemon1", {
      scrollTrigger: {
        trigger: ".aPlaceToBelongOrangeLemon1",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: 80,
      y: 30,
      rotate: 30,
    });
    gsap.to(".aPlaceToBelongOrangeLemon2", {
      scrollTrigger: {
        trigger: ".aPlaceToBelongOrangeLemon2",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: -20,
      y: 80,
      rotate: -40,
    });
    gsap.to(".aPlaceToBelongBlueCircle", {
      scrollTrigger: {
        trigger: ".aPlaceToBelongBlueCircle",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: -50,
      y: 40,
    });
    gsap.to(".aPlaceToBelongSmBicolorSquare", {
      scrollTrigger: {
        trigger: ".aPlaceToBelongSmBicolorSquare",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: -80,
      y: 10,
      rotate: -25,
    });
  }, [isOverMd]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        maxWidth: "1700px",
        margin: "auto",
        gap: { md: "48px", xs: "32px" },
        justifyContent: "space-between",
        alignItems: { md: "flex-start", xs: "center" },
        paddingTop: { md: "350px", xs: "60px" },
        paddingBottom: { md: "336px", xs: "88px" },
      }}
      className={"aPlaceToBelongScrollingImagesContainer"}
    >
      <Box
        sx={{
          backgroundImage: `url(${ScrollingPhoto3})`,
          backgroundPosition: "60% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "115% auto",
          maxWidth: "820px",
          width: { xs: "100%", md: `${(877 / 1550) * 100}%` },
          aspectRatio: `${877 / 598} / 1`,
          borderRadius: "24px",
          // border: "1px solid red",
        }}
        className="aPlaceToBelongScrollingImage1"
      />
      <Box
        sx={{
          position: "relative",
          top: { md: "-80px", xs: 0 },
          backgroundImage: `url(${ScrollingPhoto4})`,
          maxWidth: { md: "680px", xs: "unset" },
          width: { xs: "100%", md: `${(605 / 1550) * 100}%` },
          aspectRatio: `${605 / 636} / 1`,
          backgroundPosition: "50% 50%", // da modificare il secondo valore
          backgroundRepeat: "no-repeat",
          backgroundSize: "130% auto",
          borderRadius: "16px",
        }}
        className="aPlaceToBelongScrollingImage2"
      />

      {isOverMd && (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "62px",
              transform: "rotate(-137deg)",
              right: "50%",
              top: "150px",
            }}
            className="aPlaceToBelongOrangeLemon1"
          >
            <StaticImage alt="" src="../../images/OrangeLemon.svg" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "52px",
              bottom: "230px",
              transform: "rotate(130deg)",
              left: "55%",
            }}
            className="aPlaceToBelongOrangeLemon2"
          >
            <StaticImage alt="" src="../../images/OrangeLemon.svg" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "26px",
              top: "250px",
              left: "20%",
            }}
            className="aPlaceToBelongBlueCircle"
          >
            <StaticImage alt="" src="../../images/BlueCircle.svg" />
          </Box>
        </>
      )}
      {!isOverMd && (
        <Box
          sx={{
            position: "absolute",
            width: "30px",
            top: "40%",
            transform: "rotate(40deg)",
            right: "20%",
          }}
          className="aPlaceToBelongSmBicolorSquare"
        >
          <StaticImage alt="" src="../../images/BicolorSquare.svg" />
        </Box>
      )}
    </Box>
  );
};

export default ScrollingPhotos;
