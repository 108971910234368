/** @jsxImportSource @emotion/react */
import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const PhotosMosaic = () => {
  return (
    <Stack
      sx={{
        flexDirection: "column",
        width: { lg: "50%", xs: "100%" },
        // backgroundColor: "salmon",
        margin: "auto",
        flexShrink: 0,
        maxWidth: "690px",
      }}
    >
      <Stack sx={{ flexDirection: "row" }}>
        <Box>
          <StaticImage
            alt=""
            src="../../images/MosaicPhoto1.png"
            imgStyle={{ borderRadius: "190px" }}
          />
        </Box>
        <Box>
          <StaticImage
            alt=""
            src="../../images/MosaicPhoto2.png"
            imgStyle={{ borderRadius: "190px" }}
          />
        </Box>
      </Stack>
      <Stack sx={{ flexDirection: "row" }}>
        <Box
          sx={{
            width: "42%",
            height: "auto",
            backgroundColor: "rgba(255, 163, 0, 1)",
            borderRadius: "190px",
          }}
        />

        <Box>
          <StaticImage
            alt=""
            src="../../images/MosaicPhoto3.png"
            imgStyle={{ borderRadius: "190px" }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default PhotosMosaic;
