import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to(".makeOurEveryProjectSmBlueLemon", {
      scrollTrigger: {
        trigger: ".makeOurEveryProjectSmBlueLemon",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      y: 40,
      x: -10,
      rotate: 70,
    });
  }, [isOverMd]);

  return (
    <Box
      sx={{
        maxWidth: { lg: "800px", xs: "unset" },
        height: "fit-content",
        position: "relative",
      }}
    >
      <LifeatmTypography
        variant="title1"
        sx={{ marginBottom: { md: "56px", xs: "24px" } }}
      >
        {l_lifeatm("makeOurEveryProject.title")}
      </LifeatmTypography>
      <LifeatmTypography variant="body1" sx={{ marginBottom: "24px" }}>
        {l_lifeatm("makeOurEveryProject.description1")}
      </LifeatmTypography>
      <LifeatmTypography variant="body1">
        {l_lifeatm("makeOurEveryProject.description2")}
      </LifeatmTypography>

      {!isOverMd && (
        <Box
          sx={{
            position: "absolute",
            top: "0%",
            right: "-20px",
            width: "36px",
            transform: "rotate(-58deg)",
          }}
          className={"makeOurEveryProjectSmBlueLemon"}
        >
          <StaticImage src="../../images/BlueLemon.svg" alt="" />
        </Box>
      )}
    </Box>
  );
};

export default Title;
