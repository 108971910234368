import { Typography, TypographyProps } from "@mui/material";

type LifeatmTypographyProps = {
  variant?: "title1" | "overtitle" | "body1" | "span";
} & Omit<TypographyProps, "variant" | "color">;

const LifeatmTypography: React.FC<LifeatmTypographyProps> = ({
  variant,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={
        variant === "span"
          ? "span"
          : variant === "title1"
          ? "h1"
          : variant === "overtitle"
          ? "h4"
          : "p"
      }
      sx={{
        color: "#000",
        ...(variant === "title1"
          ? {
              fontSize: { xl: "96px", lg: "80px", md: "64px", xs: "40px" },
              lineHeight: { xl: "100px", lg: "84px", md: "68px", xs: "44px" },
              fontWeight: 700,
            }
          : variant === "body1"
          ? {
              fontSize: { md: "24px", xs: "16px" },
              lineHeight: { md: "32px", xs: "20px" },
              fontWeight: 400,
              color: "rgba(0, 0, 0, .6)",
            }
          : variant === "overtitle"
          ? {
              fontSize: { md: "21px", xs: "16px" },
              lineHeight: { md: "21px", xs: "20px" },
              fontWeight: 700,
              textTransform: "uppercase",
            }
          : // : variant === "title2"
          // ? {
          //     fontSize: "24px",
          //     lineHeight: "32px",
          //     fontWeight: 800,
          //   }
          // : variant === "body2"
          // ? {
          //     fontSize: "20px",
          //     lineHeight: "28px",
          //     fontWeight: 400,
          //     color: "#00000060",
          //   }
          variant === "span"
          ? {
              fontSize: "inherit",
              lineHeight: "inherit",
              fontWeight: "inherit",
            }
          : {}),
        ...sx,
      }}
      {...props}
    />
  );
};
export default LifeatmTypography;
