import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
// @ts-ignore
import ScrollingPhoto1 from "../../images/ScrollingPhoto1.png";
// @ts-ignore
import ScrollingPhoto2 from "../../images/ScrollingPhoto2.png";

const ScrollingPhotos = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      ".ourValuesScrollingImage1",
      {
        backgroundPosition: "60% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".ourValuesScrollingImagesContainer",
          scrub: 1,
          start: "top center",
          end: "bottom bottom",
        },
        backgroundPosition: "60% 100%",
      }
    );
    gsap.fromTo(
      ".ourValuesScrollingImage2",
      {
        backgroundPosition: "60% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".ourValuesScrollingImagesContainer",
          scrub: 1,
          start: "top center",
          end: "bottom bottom",
        },
        backgroundPosition: "60% 100%",
      }
    );

    gsap.to(".ourValuesOrangeLemon1", {
      scrollTrigger: {
        trigger: ".ourValuesOrangeLemon1",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: 80,
      y: 50,
      rotate: -30,
    });
    gsap.to(".ourValuesOrangeLemon2", {
      scrollTrigger: {
        trigger: ".ourValuesOrangeLemon2",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: 60,
      y: 60,
      rotate: -40,
    });
    gsap.to(".ourValuesBlueCircle", {
      scrollTrigger: {
        trigger: ".ourValuesBlueCircle",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: -40,
      y: 120,
    });
    gsap.to(".ourValuesSmBlueLemon", {
      scrollTrigger: {
        trigger: ".ourValuesSmBlueLemon",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: -80,
      y: 10,
      rotate: 45,
    });
    gsap.to(".ourValuesSmOrangeLemon", {
      scrollTrigger: {
        trigger: ".ourValuesSmOrangeLemon",
        start: "-200px center",
        end: "200px center",
        scrub: 2,
      },
      x: 35,
      y: 45,
      rotate: 65,
    });
  }, [isOverMd]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        maxWidth: "1700px",
        margin: "auto",
        gap: { md: "48px", xs: "32px" },
        justifyContent: "space-between",
        alignItems: { md: "flex-start", xs: "center" },
        paddingTop: { md: "330px", xs: "60px" },
        paddingBottom: { md: "520px", xs: "80px" },
      }}
      className="ourValuesScrollingImagesContainer"
    >
      <Box
        sx={{
          backgroundImage: `url(${ScrollingPhoto1})`,
          backgroundPosition: "60% 50%", // da modificare il secondo valore
          backgroundRepeat: "no-repeat",
          backgroundSize: "110% auto",
          maxWidth: "820px",
          width: { xs: "100%", md: `${(820 / 1550) * 100}%` },
          aspectRatio: `${820 / 560} / 1`,
          borderRadius: "24px",
          // border: "1px solid red",
        }}
        className="ourValuesScrollingImage1"
      />
      <Box
        sx={{
          position: "relative",
          top: { md: "120px", xs: 0 },
          backgroundImage: `url(${ScrollingPhoto2})`,
          maxWidth: { md: "680px", xs: "unset" },
          width: { xs: "100%", md: `${(680 / 1550) * 100}%` },
          aspectRatio: `${680 / 560} / 1`,
          backgroundPosition: "50% 50%", // da modificare il secondo valore
          backgroundRepeat: "no-repeat",
          backgroundSize: "130% auto",
          borderRadius: "16px",
          // border: "1px solid red",
        }}
        className="ourValuesScrollingImage2"
      />
      {isOverMd && (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "62px",
              transform: "rotate(-137deg)",
              right: "100px",
              // backgroundColor: "dodgerblue",
            }}
            className={"ourValuesOrangeLemon1"}
          >
            <StaticImage alt="" src="../../images/OrangeLemon.svg" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "62px",
              bottom: "250px",
              transform: "rotate(130deg)",
              right: "50%",
            }}
            className={"ourValuesOrangeLemon2"}
          >
            <StaticImage alt="" src="../../images/OrangeLemon.svg" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "28px",
              bottom: "400px",
              left: "20%",
            }}
            className={"ourValuesBlueCircle"}
          >
            <StaticImage alt="" src="../../images/BlueCircle.svg" />
          </Box>
        </>
      )}
      {!isOverMd && (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "30px",
              transform: "rotate(137deg)",
              left: "20%",
              top: "45%",
            }}
            className="ourValuesSmBlueLemon"
          >
            <StaticImage alt="" src="../../images/BlueLemon.svg" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "44px",
              bottom: "60px",
              transform: "rotate(-130deg)",
              right: "20%",
            }}
            className="ourValuesSmOrangeLemon"
          >
            <StaticImage alt="" src="../../images/OrangeLemon.svg" />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ScrollingPhotos;
