import { Box } from "@mui/material";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";
import PhotosCarousel from "./PhotosCarousel";

const NotOnlyCollegues = () => {
  return (
    <>
      <Box
        sx={{
          width: "90%",
          maxWidth: "1700px",
          margin: "auto",
        }}
      >
        <Box
          sx={{ maxWidth: "1250px", marginBottom: { md: "165px", xs: "72px" } }}
        >
          <LifeatmTypography
            variant="title1"
            sx={{ marginBottom: { md: "56px", xs: "24px" } }}
          >
            {l_lifeatm("notOnlyCollegues.title")}
          </LifeatmTypography>
          <LifeatmTypography variant="body1" sx={{}}>
            {l_lifeatm("notOnlyCollegues.description1")}
          </LifeatmTypography>
          <LifeatmTypography variant="body1" sx={{}}>
            {l_lifeatm("notOnlyCollegues.description2")}
          </LifeatmTypography>
        </Box>
      </Box>
      <PhotosCarousel />
    </>
  );
};

export default NotOnlyCollegues;
