import { Box, Button } from "@mui/material";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { LocalizedLink } from "../../../../components/LocalizedLink";
import { l_lifeatm } from "../../locales";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import LifeatmTypography from "../../theme/LifeatmTypography";
gsap.registerPlugin(ScrollToPlugin);

const Description = () => {
  return (
    <Box
      sx={{
        // width: { md: "45%", xs: "100%" },
        flex: 1,
        boxSizing: "border-box",
        display: { md: "block", xs: "flex" },
        flexDirection: "column",
        maxWidth: { md: "700px", xs: "unset" },
      }}
    >
      <LifeatmTypography variant="body1">
        {l_lifeatm("bePartOfOurTeam.description")}
      </LifeatmTypography>
      <LocalizedLink
        to="/careers"
        style={{
          textDecoration: "none",
          alignSelf: "center",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: "#E85322",
            backgroundColor: "transparent",
            borderRadius: "56px",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: 700,
            padding: { md: "24px 68px", xs: "20px 24px" },
            borderWidth: "2px",
            width: { xs: "100%", md: "auto" },
            maxWidth: "600px",
            margin: "32px auto auto 0px",
            marginTop: "32px",
            ":hover": {
              borderColor: "inherit",
              backgroundColor: "#E8532212",
              borderWidth: "2px",
            },
          }}
          startIcon={<ButtonDotIcon />}
        >
          {l_lifeatm("bePartOfOurTeam.buttonText")}
        </Button>
      </LocalizedLink>
    </Box>
  );
};
export default Description;
