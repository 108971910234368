import { Grid, Typography } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useMemo } from "react";
import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";

const ValuesGrid = () => {
  gsap.registerPlugin(ScrollTrigger);

  const gridPoints = useMemo(() => {
    return [
      {
        title: l_lifeatm("ourValues.gridTitle1"),
        description: l_lifeatm("ourValues.gridDescription1"),
      },
      {
        title: l_lifeatm("ourValues.gridTitle2"),
        description: l_lifeatm("ourValues.gridDescription2"),
      },
      {
        title: l_lifeatm("ourValues.gridTitle3"),
        description: l_lifeatm("ourValues.gridDescription3"),
      },
    ];
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ".ourValuesGridItem",
      {
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: ".ourValuesGridContainer",
          start: "top 80%",
          end: "center center",
          scrub: true,
        },
        opacity: 1,
      }
    );
  }, []);

  return (
    <Grid
      container
      spacing={"32px"}
      sx={{
        justifyContent: { md: "space-between", xs: "center" },
        boxSizing: "border-box",
      }}
      className="ourValuesGridContainer"
    >
      {gridPoints.map((gridPoint) => (
        <Grid
          item
          md={4}
          xs={12}
          sx={{ maxWidth: { md: "480px !important", xs: "unset" } }}
          key={gridPoint.title}
          className="ourValuesGridItem"
        >
          <Typography
            component="h2"
            sx={{
              marginBottom: { md: "16px", xs: "8px" },
              fontSize: { md: "36px", xs: "24px" },
              lineHeight: { md: "44px", xs: "32px" },
              fontWeight: 700,
            }}
          >
            {gridPoint.title}
          </Typography>
          <LifeatmTypography variant="body1">
            {gridPoint.description}
          </LifeatmTypography>
        </Grid>
      ))}
    </Grid>
  );
};

export default ValuesGrid;
