import { Box } from "@mui/material";

import { l_lifeatm } from "../../locales";
import LifeatmTypography from "../../theme/LifeatmTypography";
import ScrollingPhotos from "./ScrollingPhotos";
import ValuesGrid from "./ValuesGrid";

const OurValues = () => {
  return (
    <Box
      sx={{
        width: "90%",
        margin: "auto",
        paddingTop: { md: "225px", xs: "88px" },
        maxWidth: "1700px",
      }}
    >
      <LifeatmTypography
        variant="title1"
        sx={{ marginBottom: { md: "160px", xs: "88px" } }}
      >
        {l_lifeatm("ourValues.title")}
      </LifeatmTypography>
      <ValuesGrid />
      <ScrollingPhotos />
    </Box>
  );
};

export default OurValues;
